























 import { Notify } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import { Vue,Component } from "vue-property-decorator"
 import Stroage from "../../util/Storage"
 import { SetUserData,GetUserData } from "../../Api/Basics/index"
 import ZoomPage from "@/impView/PageSafety";

 interface SignatuireType<T=string>{
     value :T;
     oldValue :T;
     loadingShow:boolean;
     userId:T|number;
     token:T;

     handleUpMood():void;
     handleError(data:string):void;
 }

 @Component({ name:"UserSignature",components:{ HeadTop } })
 export default class UserSignature extends ZoomPage implements SignatuireType{
   value = ""
   oldValue = "";
   loadingShow = true;
   token = "";
   userId = "";

   mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.value = Stroage.GetData_ && JSON.parse( Stroage.GetData_("UserData") ).mood || "这个人太懒什么都没有留下~~"
        this.oldValue = this.value
   }

    handleUpMood(){
        if( !this.value ){ this.handleError("请输入个性签名"); return; }
        let reg = / /g
        if( this.value.match(reg)?.length! > 5 ){ this.handleError("输入的内容超过5个空格");return; }
        if( this.value == this.oldValue ){ this.handleError("输入的内容和旧聂荣一致");return; }

        this.loadingShow = true
        new Promise( (reslove,reject)=>{
            SetUserData({
                userId:this.userId,
                token:this.token
            },{
                mood:this.value
            }).then((res:any)=>{
                console.log( res );
                if( res.message.code === "200" ){
                    reslove( true )
                }else{
                    reject( res )
                }
            })
        } ).then( (res:any)=>{
            GetUserData({
                userId:this.userId,
                token:this.token
            }).then( (res:any)=>{
                console.log( res );
                this.loadingShow = false
                if( res.message.code === "200" ){
                    this.value = res.data.mood
                    Stroage.SetData_  && Stroage.SetData_ ("UserData",JSON.stringify( res.data ))
                }else{
                    this.handleError( res.message.msg )
                }
            } )
        } ).catch(cat=>{
            this.loadingShow = false
            this.handleError( cat.message.msg )
        })

    }

   handleError( data:string ){
    let str = ""
    if ( data.length ){
        str = data
    }else{
        str = "网络繁忙..."
    }
    Notify({
        message: str,
        color: 'white',
        background: "rgba(0,0,0,.6)",
    });
}
 }
